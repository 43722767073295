import { Component, Inject, OnInit } from '@angular/core';
import { CR_CONFIG, CR_CONSTANTS, ErrorLoggingService, VenueService } from "../../shared";
import { Config, Constants, LocalizedText } from '../../core';
import text from './resources/locale/en.json';
import { PoiService } from "../../shared/services/poi/poi.service";
import { StateService } from "@uirouter/core";
import _ from "lodash";
import { POI } from "../../shared/models/poi.model";
import { EntityType } from "../../shared/models/entity.model";
import { EntityService } from "../../shared/services/entity/entity.service";
import { ToastService } from "../../ng1-factories";
import { BooleanUrls, Extension, GroomedReportModel, PoiExtension, PoiModel } from "./common/types";
import { ReportFileService } from '../../shared/services/report-file/report-file.service';
import { InternalLegacyStatuses } from './constants/statuses';
import { getAutoStatusInfo, getIconUrl, getStatusUrl } from './common/common.util';
import { statusList } from './constants/options';
import { HttpErrorResponse } from '@angular/common/http';
import FeatureFlagsService from '../../shared/services/feature-flags/feature-flags';

@Component({
    selector: 'cr-lifts-trails-activities',
    templateUrl: './lifts-trails-activities.component.html',
    styleUrls: ['./lifts-trails-activities.component.scss']
})
export class LiftsTrailsActivitiesComponent implements OnInit {

    text: LocalizedText;
    isLoading = false;
    isSaving = false;
    isAdd = false;
    accessError: any;
    dataError = false;
    params: any;
    poiList: POI[];
    filteredPoi: PoiModel[];
    filteredTrailsList: PoiModel[];
    timezone: string;
    currentTabId = 'lift';
    defaultType = this.state.params.tabId ?? 'lift';

    constructor(
        @Inject(CR_CONFIG) public config,
        @Inject(CR_CONSTANTS) public constants: Constants,
        private crPoiService: PoiService,
        public state: StateService,
        private crErrorLoggingService: ErrorLoggingService,
        private crEntityService: EntityService,
        private crToastService: ToastService,
        private crReportFileService: ReportFileService,
        private crFeatureFlagService: FeatureFlagsService,
        venueService: VenueService,
    ) {
        this.text = text as LocalizedText;
        this.params = {
            "page": 1,
            "perPage": 0,
            "sort": "asc",
            "sortby": "name",
            "search": "",
            "venueId": state.params.venueId,
            "category": "",
            "state": "ACTIVE"
        };
        this.currentTabId = state.params.tabId || 'lift';
        this.timezone = venueService.getTimezone(state.params.venueId).name;
    }

    ngOnInit(): void {
        this.getData();
        this.getPoiList();
    }

    getPoiList() {
        this.isLoading = true;

        const queryParams: any = _.pickBy(this.params, (value) => !_.isNil(value) && value !== '' && value !== 'all');
        return this.crPoiService.getPois(queryParams)
            .then((data: any) => data.content as PoiModel[])
            .then((data) => {
                this.filteredPoi = data
                    .filter(p => !p.extensions.find((e: PoiExtension) => (/^(internalLift|internalTrail|internalActivity)/).exec(e.id)));
                this.filteredTrailsList = data
                    .filter(p => p.extensions.find((e: PoiExtension) => (/^internalTrail/).exec(e.id)));
            })
            .catch((err) => {
                this.filteredPoi = [];
                this.crErrorLoggingService.logError('Could not get lifts data.', err);
            })
            .finally(() => this.isLoading = false);
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;

        const queryParams = _.pickBy(this.params, (value) => !_.isNil(value) && value !== '' && value !== 'all');
        return this.crPoiService.getPois(queryParams)
            .then((data) => this.poiList = data.content)
            .catch((err) => {
                this.dataError = true;
                this.poiList = [];
                this.crErrorLoggingService.logError('Could not get lifts data.', err);
            })
            .finally(() => this.isLoading = false);
    }

    public savePoiFunction = this.savePoi.bind(this);

    private savePoi(poiToSave) {
        this.isSaving = true;
        return new Promise((resolve, reject) => {
            const payload = this.crEntityService.prepareEntityPayload(poiToSave);
            const {venueId} = this.state.params;
            this.crEntityService
                .updateEntity(EntityType.POIS, poiToSave.id, payload, venueId)
                .then(() => {
                    this.crToastService.toast(`Data successfully updated.`, 10);
                    resolve(poiToSave);
                })
                .catch((err) => {
                    this.handleError('Could not update poi', err);
                    reject(err);
                })
                .finally(() => this.isSaving = false);
        });
    }

    private handleError(couldNotUpdatePoi: string, err: any) {
        this.crErrorLoggingService.logError(couldNotUpdatePoi, err);
    }

    setTab(tabId: string) {
        this.defaultType = tabId;
        this.state.go('.', { tabId: tabId }, { location: 'replace' });
        this.isAdd = false;
    }

    onSelectPoi(poi) {
        this.state.go('client.lifts-trails-activities.add', {
            poiId: poi.id,
            tabId: this.state.params.tabId || 'lift'
        });
    }

    displayPrintIcon() {
        return this.crFeatureFlagService.hasFeature('print_groomed_trails');
    }

    exportTrailsData() {
        const today = new Date();
        this.crReportFileService.getPrintedGroomingReportTemplate(this.state.params.venueId).then((data) =>  {
                const templateDocument = data

                templateDocument.querySelector('#reportDate').innerHTML = today.toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })

                const tableBody = templateDocument.querySelector('#trail-body');

                const groomedTrailList = this.filterByGroomingTrails();
                groomedTrailList.forEach((groomedTrail) => {
                    const tableRow = templateDocument.createElement('tr');

                    const difficultyCell = this.createDifficultyElement(templateDocument, groomedTrail);
                    const statusCell = this.createStatusCell(templateDocument, groomedTrail);
                    const groomingCell = this.createBooleanCell(templateDocument, groomedTrail.grooming);
                    const snowmakingCell = this.createBooleanCell(templateDocument, groomedTrail.snowmaking);

                    tableRow.appendChild(difficultyCell);
                    tableRow.appendChild(statusCell);
                    tableRow.appendChild(groomingCell);
                    tableRow.appendChild(snowmakingCell);

                    tableBody.appendChild(tableRow);
                });

                const templateHtml = templateDocument.documentElement.outerHTML;

                console.log(templateHtml);

                this.generatePdf(templateHtml);
            }).catch((err) => {
                    if((err as HttpErrorResponse).status === 404) {
                        this.crToastService.toast(this.text.templateNotFound, 10);
                    } else {
                        console.error(err);
                        this.crToastService.toast(this.text.unknownError, 10);
                    }
                }
            );
    }

    filterByGroomingTrails(): GroomedReportModel[] {
        const groomedTrailList: GroomedReportModel[] = []
        this.filteredTrailsList.forEach((poi) => {
            const legacyStatus = poi.extensions.find(e => e.id === InternalLegacyStatuses.INTERNAL_LEGACY_CURRENT_STATUS);
            if(!legacyStatus)
                return;

            poi.extensions.forEach(ex => {
                const groomedReportModel: GroomedReportModel = this.createGroomedReportModel(poi, ex, legacyStatus);

                if(groomedReportModel.grooming === true) {
                    groomedTrailList.push(groomedReportModel)
                }
            })
        })

        return groomedTrailList;
    }

    createGroomedReportModel(poi: PoiModel, ex: Extension, legacyStatus: Extension): GroomedReportModel {
        const iconUrl = getIconUrl(ex.value.SNOAA_Type, ex.value.trailIcon, this.text.imageUrl);

        const statusLabel = statusList.find(s => s.id === ex.value.statusLabel)?.label ?? '';

        const data: GroomedReportModel = {
            name: ex.value.name,
            iconUrl: iconUrl,
            operationStatus: {
                label: statusLabel,
                status: legacyStatus.value?.operationalStatus?.toLowerCase() ?? '',
                color: ex.value.statusColor
            },
            grooming: ex.value.grooming ?? false,
            snowmaking: ex.value.snowMaking ?? false
        }

        if(ex.value.autoStatus) {
            data.operationStatus = getAutoStatusInfo(ex.value, this.text, this.timezone);
        }

        return data;
    }

    createDifficultyElement(templateDocument: Document, groomedTrail: GroomedReportModel): HTMLTableCellElement {
        const difficulty = templateDocument.createElement('td');
        difficulty.setAttribute('class', 'trail-cell')
        const difficultyIcon = templateDocument.createElement('img')
        difficultyIcon.setAttribute('src', `${groomedTrail.iconUrl.replace('.svg','.png')}`);
        difficultyIcon.setAttribute('alt', 'Difficulty Icon');
        difficultyIcon.setAttribute('class', 'trail-icon');
        difficulty.textContent = groomedTrail.name
        difficulty.prepend(difficultyIcon);

        return difficulty;
    }

    createStatusCell(templateDocument: Document, groomedTrail: GroomedReportModel): HTMLTableCellElement {
        const status = templateDocument.createElement('td');
        const statusCell = templateDocument.createElement('div');
        statusCell.setAttribute('class','status-cell');

        const statusImgUrl = getStatusUrl(groomedTrail.operationStatus.color, groomedTrail.operationStatus.status, this.text);

        const statusImg = templateDocument.createElement('img');
        statusImg.setAttribute('src', `${statusImgUrl}`);
        statusImg.setAttribute('class', 'status-icon');

        statusCell.textContent = groomedTrail.operationStatus.label;
        statusCell.prepend(statusImg);
        status.appendChild(statusCell);

        return status;
    }

    createBooleanCell(templateDocument: Document, booleanIndicator: boolean): HTMLTableCellElement {
        const booleanCell = templateDocument.createElement('td');

        let booleanImgUrl = ''
        if(booleanIndicator) {
            booleanImgUrl = BooleanUrls.TRUE;
        } else {
            booleanImgUrl = BooleanUrls.FALSE
        }

        const booleanImg = templateDocument.createElement('img');
        booleanImg.setAttribute('src', booleanImgUrl);
        booleanImg.setAttribute('class', 'boolean-icon');

        booleanCell.appendChild(booleanImg);

        return booleanCell;
    }

    generatePdf(templateHtml: string) {
        const iframe = document.createElement('iframe');

        iframe.style.position = 'absolute';
        iframe.style.top = '0';
        iframe.style.left = '0';
        iframe.style.width = '0';
        iframe.style.height = '0';
        iframe.style.border = 'none';

        document.body.appendChild(iframe);

        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(templateHtml);
        iframeDoc.close();

        iframe.onload = () => {
            this.addPrintStyles(iframeDoc);

            iframe.contentWindow.focus();
            iframe.contentWindow.print();

            setTimeout(() => {
                document.body.removeChild(iframe);
            }, 1000);
        }
    }

    addPrintStyles(doc) {
        const printStyles = `@media print {
            * {
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
            }
        }`;
        const styleElement = doc.createElement('style');
        styleElement.textContent = printStyles;
        doc.head.appendChild(styleElement);
    }
}
