import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/rest/reports';
  }

  getMessageConversion(id: string, params: any) {
    return this.http.get(`${this.baseUrl}/${id}`, { params }).toPromise();
  }

  getVenueUsers(venueId: string) {
    return this.http.get(`${this.baseUrl}/${venueId}/users`).toPromise();
  }

  getVenueConversions(venueId: string, params: any) {
    return this.http.get(`${this.baseUrl}/${venueId}/conversions`, { params }).toPromise();
  }

  getUserMessageReport(venueId: string, params: any) {
    return this.http.get(`${this.baseUrl}/${venueId}/experience/user`, { params }).toPromise();
  }
}
