import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReportFile } from "../../models/report-file.model";

@Injectable({
    providedIn: 'root',
})
export class ReportFileService {
    constructor(private http: HttpClient) {}

    public getReportFile(venueId: string): Promise<ReportFile[]> {
        return this.http.get<ReportFile[]>(`/rest/report-file/${venueId}`).toPromise().then((res) => res);
    }

    public getPrintedGroomingReportTemplate(venueId: string): Promise<Document> {
        return this.http.get<any>(`/rest/report-file/${venueId}/grooming-template`).toPromise().then((res) => {
            const dp = new DOMParser();
            const templateDocument = dp.parseFromString(res.data, 'text/html');
            return templateDocument;
        });
    }

    public getFrameSrcUrl(report: ReportFile): string {
        let url: string = null;

        if (report && report.venueId && report.fileName) {
            url = `/rest/report-file/${report.venueId}/${encodeURIComponent(report.fileName)}#view=fitH&toolbar=0`;
        }

        return url;
    }
}
