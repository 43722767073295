import controller from './experience-report.controller';
import template from './experience-report.html';

import './experience-report.less';

const component = {
    template,
    bindings: {
        group: '@?',
    },
    controller: [
        '$filter',
        '$state',
        'crConstants',
        'crExperiencesService',
        'crNavigationService',
        'crReportsService',
        'crScheduleService',
        'crToastService',
        controller,
    ],
};

export default component;
