import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import _ from 'lodash';
import { Config } from '../../../core/config';
import { LocalizationByIdResponse } from '../../models/entity-localization.model';
import { EntityType } from '../../models/entity.model';
import { CR_CONFIG } from '../config/cr-config';
import { LocalizationService } from '../localization/localization.service';
import { UpdatedSchedule } from '../../../client/venue-hours-angular/constants/hours.types';

@Injectable({
    providedIn: 'root',
})
export class EntityService {
    constructor(protected http: HttpClient,
                protected localizationService: LocalizationService,
                @Inject(CR_CONFIG) protected config: Config) {}

    public getEntity(entityType: EntityType, id: string, venueId: string, locale: string, entityRoute: string, additionalParams: { [param: string]: string } = null): Promise<Record<string, unknown>> {
        if (this.config.customer.localizationEnabled && this.localizationService.isLocalized(entityType)) {
            return this.getEntityWithLocalization(entityType, id, venueId, locale, entityRoute, additionalParams);
        }
        return this.getEntityWithDefaultLocalization(entityType, id, venueId, locale, entityRoute, additionalParams);
    }

    public getLocalizationFromIdList(entityType: EntityType, idList: string[], venueId: string): Promise<LocalizationByIdResponse[]> {
        return Promise.all(idList.map((id) => this.getLocalization(entityType, id, venueId)));
    }

    public createEntity(entityType: EntityType,
                        payload: { localization?: Record<string, unknown> } & Record<string, unknown>,
                        venueId: string,
                        entityRoute: string = entityType,
                        additionalParams: { [param: string]: string } = null
                        ): Promise<{ id: string } & Record<string, unknown>> {
        return this.http
            .post<{ id: string } & Record<string, unknown>>(`/rest/${entityRoute}`, payload, { params: { ...additionalParams, venueId } })
            .toPromise()
            .then((res) => {
                if (this.config.customer.localizationEnabled && this.localizationService.isLocalized(entityType)) {
                    return this.localizationService
                        .updateEntityLocalization(entityType, payload.localization, { id: res.id, venueId })
                        .then(() => res);
                }
                return res;
            });
    }

    public updateEntity(entityType: EntityType,
                        id: string,
                        payload: { localization?: Record<string, unknown> } & Record<string, unknown>,
                        venueId: string,
                        entityRoute: string = entityType,
                        additionalParams: { [param: string]: string } = null
                        ): Promise<Record<string, unknown>> {
        return this.http
            .put<Record<string, unknown>>(`/rest/${entityRoute}/${id}`, payload, { params: { ...additionalParams, venueId } }).toPromise()
            .then((res) => {
                if (this.config.customer.localizationEnabled && this.localizationService.isLocalized(entityType)) {
                    return this.localizationService
                        .updateEntityLocalization(entityType, payload.localization, { id, venueId })
                        .then(() => res);
                }
                return res;
            });
    }

    public deleteEntity(entityRoute: string,
                        id: string,
                        venueId: string,
                        additionalParams: { [param: string]: string } = null
                        ): Promise<Record<string, unknown>> {
        return this.http.delete<Record<string, unknown>>(`/rest/${entityRoute}/${id}`, { params: venueId ? { ...additionalParams, venueId } : { ...additionalParams } }).toPromise();
    }
    public updateState(entityRoute: string,
                       id: string,
                       data: Record<string, unknown>,
                       venueId: string,
                       additionalParams: { [param: string]: string } = null
                       ): Promise<Record<string, unknown>> {
        return this.http.put<Record<string, unknown>>(`/rest/${entityRoute}/${id}/state`, data, { params: { ...additionalParams, venueId } }).toPromise();
    }

    public getEntityList(entityRoute: string, params: { [param: string]: string }, locale: string): Promise<any> {

        return this.http
            .get<any>(`/rest/${entityRoute}`, {
                params: params,
                headers: locale ? {
                    'Accept-Language': locale,
                } : {},
            })
            .toPromise()
            .then((res) => res );
    }

    public getVenueHoursByDay(entityRoute: string, params: { [param: string]: string}, locale: string, customerId: string): Promise<any> {
        return this.http
            .get<any>(`/rest/${entityRoute}/get-venue-hours`, {
                params: params,
                headers: locale ? {
                    'Accept-Language': locale,
                    'X-Customer-ID': customerId
                } : {
                    'X-Customer-ID': customerId
                }
            })
            .toPromise()
            .then((res) => res)
    }

    public updateVenueHours(entityRoute: string, params: { [param: string]: string}, locale: string, customerId: string, data: Record<string, unknown>): Promise<any> {
        return this.http.put<any>(`/rest/${entityRoute}/update-venue-hours`, data, {
                params: params,
                headers: locale ? {
                    'Accept-Language': locale,
                    'X-Customer-ID': customerId
                } : {
                    'X-Customer-ID': customerId
                }
            }
        ).toPromise().then((res) => res);
    }

    public updateRanks(data: Record<string, unknown>,
                       entityRoute: string,
                       venueId: string,
                       additionalParams: { [param: string]: string }
                       ): Promise<Record<string, unknown>> {
        return this.http.put<Record<string, unknown>>(`/rest/${entityRoute}/ranks`, data, { params: { ...additionalParams, venueId } }).toPromise();
    }

    public getCategoriesWithHierarchy(entityType: EntityType,
                                      venueId: string,
                                      locale: string,
                                      entityRoute: string = entityType,
                                      additionalParams: { [param: string]: string } = null
                                      ): Promise<any[]> {
        const config = {
            params: { ...additionalParams, venueId },
            headers: locale ? { 'Accept-Language': locale } : {},
        };

        return this.http.get<any[]>(`/rest/${entityRoute}/hierarchy`, config)
            .toPromise()
            .then((res: any[]) =>
                res.map((item) => {
                    item.indent = !!item.parentId;
                    return item;
                })
            );
    }

    public getAttributes(entityType: EntityType, params: { [param: string]: string }): Promise<Record<string, unknown>> {
        return this.http.get<Record<string, unknown>>(`/rest/${entityType}`, { params }).toPromise().then((res) => res);
    }

    public getAssociatedEntities(entityRoute: string, id: string, venueId: string): Promise<Record<string, unknown>> {
        return this.http
            .get<Record<string, unknown>>(`/rest/${entityRoute}/${id}/associated-entities`, { params: { venueId } })
            .toPromise()
            .then((res) => res);
    }

    public prepareEntityPayload(entityData: { attributes?: Record<string, unknown>[], extensions?: ({ value?: string } & Record<string, unknown>)[],
                                displayCategories?: Record<string, unknown>[] } & Record<string, unknown>
                                ): { attributes?: Record<string, unknown>[], extensions?: Record<string, unknown>[], displayCategories?: Record<string, unknown>[] } & Record<string, unknown> {
        entityData = _.cloneDeep(entityData);
        return {
            ...entityData,
            attributes: (entityData.attributes || []).map((attribute) => ({
                id: attribute.id,
                name: attribute.label,
                value: attribute.value,
            })),
            extensions: (entityData.extensions || []).map((extension) => ({
                id: extension.id,
                value: JSON.parse(extension.value),
            })),
            displayCategories: (entityData.displayCategories || []).map((category) => ({
                id: category.id,
            })),
        };
    }

    protected getEntityById(entityType: EntityType,
                            id: string,
                            locale: string,
                            entityRoute: string = entityType,
                            venueId: string,
                            additionalParams: Record<string, unknown> = null
                            ): Promise<{ localization?: Record<string, unknown> } & Record<string, unknown>> {
        const params: any = venueId ? { ...additionalParams, venueId } : { ...additionalParams };
        return this.http.get<{ localization?: Record<string, unknown> } & Record<string, unknown>>(`/rest/${entityRoute}/${id}`, {
            params: { ...params },
            headers: {
                'Accept-Language': locale,
            },
        }).toPromise();
    }

    protected getLocalization(entityType: EntityType, id: string, venueId: string): Promise<LocalizationByIdResponse> {
        return new Promise((resolve, reject) => {
            this.localizationService
                .getEntityLocalization(entityType, { id, venueId })
                .then((res) => resolve(res))
                .catch((err: HttpErrorResponse) => {
                    if (err.status === 404) {
                        resolve({});
                    } else {
                        reject();
                    }
                });
        });
    }

    protected getEntityWithDefaultLocalization(entityType: EntityType,
                                               id: string,
                                               venueId: string,
                                               locale: string,
                                               entityRoute: string,
                                               additionalParams: Record<string, unknown> = null
                                               ): Promise<{ localization?: Record<string, unknown> } & Record<string, unknown>> {
        return this.getEntityById(entityType, id, locale, entityRoute, venueId, additionalParams).then((res) => {
            // only add localization property for supported entity types
            if (this.localizationService.isLocalized(entityType)) {
                res.localization = this.localizationService.getLocalizationDefaultModel(entityType, res, venueId);
            }

            return res;
        });
    }

    private getEntityWithLocalization(entityType: EntityType,
                                      id: string,
                                      venueId: string,
                                      locale: string,
                                      entityRoute: string,
                                      additionalParams: Record<string, unknown> = null
                                      ): Promise<{ localization?: Record<string, unknown> } & Record<string, unknown>> {
        return Promise
            .all([
                this.getEntityById(entityType, id, locale, entityRoute, venueId, additionalParams),
                this.getLocalization(entityType, id, venueId),
            ])
            .then((res) => {
                const data = res[0];

                if (_.isEmpty(res[1])) {
                    data.localization = this.localizationService.getLocalizationDefaultModel(
                        entityType,
                        data,
                        venueId
                    );
                } else {
                    data.localization = res[1];
                }

                return data;
            });
    }
}
