export type StatusOption = {
    id: string,
    label: string,
    isSelected: boolean,
    type: string,
    color: string,
    visibleIn: string[],
};

export type OperationStatus = {
    label: string,
    status: string,
    color: string,
};

export type PoiModel = {
    id: string;
    name: string;
    title: string;
    isDisabled: boolean;
    extensions: Extension[];
}

export type Extension = {
    id: string;
    value: any;
}

export type SNOAAExtensionValue = {
    hours?: {};
    SNOAAType?: string;
    autoStatus?: boolean;
    statusLabel?: string;
    statusColor?: string;
    mountainArea?: string;
    type?: string;
    glades?: boolean;
    grooming?: boolean;
    moguls?: boolean;
    nightSkiing?: boolean;
    snowMaking?: boolean;
    trailIcon?: string;
    difficulty?: string;
    name?: string;
}

export type SNOAALegacyExtensionValue = {
    operationalStatus: string;
    waitTime: string;
}

export type paramValue = {
    page: number;
    perPage: number;
    sort: string;
    sortby: string;
    search: string;
    venueId: string;
    category: string;
    state: string;
}

export type PoiExtension = {
    id: string;
    value: {};
}

export type PoiListModel = {
    content: PoiModel[],
    query: Record<string, any>,
}

export type FormValues = {
    mountainArea: string;
    waitTime: string;
    monday_open: string;
    monday_close: string;
    tuesday_open: string;
    tuesday_close: string;
    wednesday_open: string;
    wednesday_close: string;
    thursday_open: string;
    thursday_close: string;
    friday_open: string;
    friday_close: string;
    saturday_open: string;
    saturday_close: string;
    sunday_open: string;
    sunday_close: string;
}

export type GroomedReportModel = {
    name: string
    iconUrl: string,
    operationStatus: OperationStatus,
    grooming: boolean,
    snowmaking: boolean
}

export const ColourSwatch = {
    BLUE: "#05ADFC",
    RED: "#A02040",
    GREEN: "#36B44C",
    ORANGE: "#FFB400"
}

export const ActiveUrls = {
    BLUE: "https://global-icons.s3.us-east-1.amazonaws.com/grooming-template-icons/cr-active-blue.png",
    GREEN: "https://global-icons.s3.us-east-1.amazonaws.com/grooming-template-icons/cr-active-green.png",
    ORANGE: "https://global-icons.s3.us-east-1.amazonaws.com/grooming-template-icons/cr-active-orange.png"
}

export const ClosedUrls = {
    RED: "https://global-icons.s3.us-east-1.amazonaws.com/grooming-template-icons/cr-close-red.png",
    ORANGE: "https://global-icons.s3.us-east-1.amazonaws.com/grooming-template-icons/cr-close-orange.png"
}

export const BooleanUrls = {
    TRUE: "https://global-icons.s3.us-east-1.amazonaws.com/grooming-template-icons/cr-true.png",
    FALSE: "https://global-icons.s3.us-east-1.amazonaws.com/grooming-template-icons/cr-close-red.png"
}
